
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { convertEmptyToNull } from '@/util/helper';
import { CustomerGroup } from '@/enums/CustomerGroup';

@Component({
  components: { VFormBuilder },
})
export default class AvailabilityForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: false }) public disableWeb!: boolean;
  @Prop({ type: Boolean, default: false }) public disableTerminal!: boolean;
  @Prop({ type: Boolean, default: false }) public disableCatering!: boolean;
  @Prop({ type: Boolean, default: false }) public disableStandard!: boolean;
  @Prop({ type: String, default: '' }) public customerGroup!: CustomerGroup;
  @Prop({ type: Boolean, default: false }) public disablePreorderDelivery!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderTakeAway!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderInside!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderParkCollect!: boolean;

  public reset() {
    if (!this.disableStandard) {
      this.$refs.standard.reset();
    }
    if (!this.disableTerminal) {
      this.$refs.terminal.reset();
    }
    if (!this.disableCatering) {
      this.$refs.catering.reset();
    }
    this.$refs.preorder.reset();
    this.$refs.byWeekday.reset();
  }

  public getData() {
    const data: any = {
      preorder: {
        ...convertEmptyToNull(this.$refs.preorder.form),
      },
      catering: {
        ...convertEmptyToNull(this.$refs.catering.form),
      },
      byWeekday: {
        ...convertEmptyToNull(this.$refs.byWeekday.form),
      },
    };
    if (!this.disableStandard) {
      data.standard = this.$refs.standard.form.standard === '' ? null : this.$refs.standard.form.standard;
    }
    if (!this.disableTerminal) {
      data.terminal = { ...convertEmptyToNull(this.$refs.terminal.form) };
    }
    if (!this.disableCatering) {
      data.catering = { ...convertEmptyToNull(this.$refs.catering.form) };
    }
    if (!this.disableWeb) {
      data.web = this.$refs.web.form.web === '' ? null : this.$refs.web.form.web;
    }

    return data;
  }

  get standard() {
    return [
      {
        name: 'standard',
        type: InputType.Checkbox,
        label: 'promo.form.standardAvailability',
        default: true,
      },
    ];
  }

  get terminal() {
    return [
      {
        name: 'inside',
        type: InputType.Checkbox,
        label: 'promo.form.insideAvailability',
        default: true,
      },
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'promo.form.takeAwayAvailability',
        default: true,
      },
    ];
  }

  get byWeekday() {
    return [
      {
        name: '0',
        type: InputType.Checkbox,
        label: 'weekdays.monday',
        default: true,
      },
      {
        name: '1',
        type: InputType.Checkbox,
        label: 'weekdays.tuesday',
        default: true,
      },
      {
        name: '2',
        type: InputType.Checkbox,
        label: 'weekdays.wednesday',
        default: true,
      },
      {
        name: '3',
        type: InputType.Checkbox,
        label: 'weekdays.thursday',
        default: true,
      },
      {
        name: '4',
        type: InputType.Checkbox,
        label: 'weekdays.friday',
        default: true,
      },
      {
        name: '5',
        type: InputType.Checkbox,
        label: 'weekdays.saturday',
        default: true,
      },
      {
        name: '6',
        type: InputType.Checkbox,
        label: 'weekdays.sunday',
        default: true,
      },
    ];
  }

  get preorder() {
    return [
      {
        name: 'inside',
        type: InputType.Checkbox,
        label: 'promo.form.insideAvailability',
        default: true,
        visible: !this.disablePreorderInside,
      },
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'promo.form.takeAwayAvailability',
        default: true,
        visible: !this.disablePreorderTakeAway,
      },
      {
        name: 'delivery',
        type: InputType.Checkbox,
        label: 'promo.form.deliveryAvailability',
        default: true,
        visible: !this.disablePreorderDelivery,
      },
      {
        name: 'parkCollect',
        type: InputType.Checkbox,
        label: 'promo.form.preorderParkCollect',
        default: true,
        visible: !this.disablePreorderParkCollect,
      },
    ];
  }

  get catering() {
    return [
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'promo.form.takeAwayAvailability',
        default: true,
      },
      {
        name: 'delivery',
        type: InputType.Checkbox,
        label: 'promo.form.deliveryAvailability',
        default: true,
      },
    ];
  }

  get web() {
    return [
      {
        name: 'web',
        type: InputType.Checkbox,
        label: 'promo.form.webAvailability',
        default: true,
      },
    ];
  }
}
