
import { Component, Emit, Prop } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import StackedForm from '@/mixins/StackedForm';
import Countries from '@/mixins/Countries';
import { Article } from '@/interfaces/models/Article';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { FormItem } from '@/interfaces/components/FormItem';
import { Coupon } from '@/interfaces/models/Coupon';
import { Country } from '@/enums/Country';
import { venueFilter } from '@/util/helper';
import { CouponType } from '@/enums/CouponType';
import { CouponUsageFrequency } from '@/enums/CouponUsageFrequency';
import { PiggyReward } from '@/interfaces/models/PiggyReward';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';

@Component({
  components: { VFormBuilder },
})
export default class GeneralForm extends mixins(CustomerGroup, StackedForm, Countries) {
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];
  @Prop({ type: Array, default: () => [] }) public articles!: Article[];
  @Prop({ type: Array, default: () => [] }) public rewards!: PiggyReward[];
  @Prop({ type: Boolean, required: true }) public editing!: boolean;
  @Prop({ type: String, default: null }) public customerGroup!: CustomerGroup;
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;

  private currentCusomerGroup: CustomerGroupEnum = CustomerGroupEnum.Default;

  public mounted() {
    if (this.initialValues && this.initialValues.hasOwnProperty('customerGroup')) {
      const { customerGroup } = this.initialValues as Coupon;
      this.currentCusomerGroup = customerGroup ? customerGroup : CustomerGroupEnum.Default;
    }
  }

  get types() {
    return [
      { text: this.$t('coupon.types.absolute'), value: CouponType.Absolute },
      { text: this.$t('coupon.types.relative'), value: CouponType.Relative },
      { text: this.$t('coupon.types.deliveryFee'), value: CouponType.Delivery_Fee },
      { text: this.$t('coupon.types.bogo'), value: CouponType.Bogo },
      { text: this.$t('coupon.types.giftCard'), value: CouponType.Gift_Card },
      /*{ text: this.$t('promo.types.reward'), value: PromoCodeType.Reward },*/
      { text: this.$t('coupon.types.freeArticle'), value: CouponType.Free_Article },
      { text: this.$t('coupon.types.plusOneFree'), value: CouponType.Plus_One_Free },
      { text: this.$t('coupon.types.void'), value: CouponType.Void },
      { text: this.$t('coupon.types.absoluteArticle'), value: CouponType.Absolute_Article },
      { text: this.$t('coupon.types.relativeArticle'), value: CouponType.Relative_Article },
    ];
  }

  get usageFrequencyTypes() {
    return [
      { text: this.$t('coupon.usageFrequency.any'), value: CouponUsageFrequency.Any },
      { text: this.$t('coupon.usageFrequency.daily'), value: CouponUsageFrequency.Daily },
      { text: this.$t('coupon.usageFrequency.weekly'), value: CouponUsageFrequency.Weekly },
      { text: this.$t('coupon.usageFrequency.monthly'), value: CouponUsageFrequency.Monthly },
    ];
  }

  get germanStates() {
    return [
      'Berlin',
      'Bayern',
      'Niedersachsen',
      'Baden-Württemberg',
      'Rheinland-Pfalz',
      'Sachsen',
      'Thüringen',
      'Hessen',
      'Nordrhein-Westfalen',
      'Sachsen-Anhalt',
      'Brandenburg',
      'Mecklenburg-Vorpommern',
      'Hamburg',
      'Schleswig-Holstein',
      'Saarland',
      'Bremen',
    ];
  }

  get austriaStates() {
    return [
      'Burgenland',
      'Kärnten',
      'Niederösterreich',
      'Oberösterreich',
      'Salzburg',
      'Steiermark',
      'Tirol',
      'Vorarlberg',
      'Wien',
    ];
  }

  get items() {
    if (this.editing) {
      return this.updateItems;
    }

    return this.createItems;
  }

  get updateItems(): FormItem[] {
    return [
      { name: 'name', type: InputType.Text, label: 'coupon.form.name', rules: 'required' },
      {
        name: 'description',
        type: InputType.Language,
        useTextarea: true,
        label: 'Description',
        rules: 'required',
      },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'coupon.form.venues',
        multiple: true,
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
      },
      {
        name: 'piggyRewardUuid',
        type: InputType.Select,
        label: 'Piggy Reward Uuid',
        items: this.rewards,
        itemText: 'title',
        itemValue: 'uuid',
        visible: !!this.rewards?.length,
        clearable: true,
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'coupon.form.type',
        items: this.types,
        visible: false,
        disabled: true,
      },
      {
        name: 'usageFrequency',
        type: InputType.Select,
        label: 'coupon.form.usageFrequency',
        items: this.usageFrequencyTypes,
      },
      {
        name: 'uploadedGiftCards',
        type: InputType.File,
        label: 'coupon.form.uploadGiftCards',
        visible: this.uploadGiftCardsVisibleCallbackDefault,
        default: null,
        accept: '.csv',
      },
      { name: 'mov', type: InputType.Text, label: 'coupon.form.mov', default: '0', rules: 'required' },
      { name: 'amount', type: InputType.Text, label: 'coupon.form.amount', default: '0', rules: 'required' },
      {
        name: 'isMultipleApplicable',
        type: InputType.Checkbox,
        label: 'coupon.form.isMultipleApplicable',
        default: false,
      },
      { name: 'active', type: InputType.Checkbox, label: 'coupon.form.active', default: true },
      {
        name: 'redeemableAtCheckoutOnly',
        type: InputType.Checkbox,
        default: false,
        label: 'coupon.form.redeemableAtCheckoutOnly',
      },
    ];
  }

  get createItems() {
    const items: FormItem[] = [
      { name: 'name', type: InputType.Text, label: 'coupon.form.name', rules: 'required' },
      { name: 'code', type: InputType.Text, label: 'coupon.form.code', rules: 'required' },
      { name: 'lightspeedCode', type: InputType.Text, label: 'coupon.form.lightspeedCode' },
      { name: 'description', type: InputType.Language, useTextarea: true, label: 'Description', rules: 'required' },
      { name: 'range', type: InputType.DateRange, rules: 'required' },
      {
        name: 'customerGroup',
        type: InputType.Select,
        default: this.customerGroup ? this.customerGroup : '',
        disabled: !!this.customerGroup,
        label: 'coupon.form.customerGroup',
        items: this.customerGroups,
        change: this.onCustomerGroupChange,
      },
      {
        name: 'countries',
        type: InputType.Select,
        multiple: true,
        label: 'promo.form.countries',
        items: this.countries,
        change: this.onCountriesChange,
      },
      { name: 'states', type: InputType.Select, multiple: true, label: 'coupon.form.states', items: this.getStates },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'coupon.form.venues',
        multiple: true,
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
        change: this.onVenueChange,
      },
      {
        name: 'piggyRewardUuid',
        type: InputType.CustomSelect,
        label: 'Piggy Reward Uuid',
        items: this.rewards,
        itemText: 'title',
        itemValue: 'uuid',
        visible: !!this.rewards?.length,
        clearable: true,
      },
      {
        name: 'usageFrequency',
        type: InputType.Select,
        label: 'coupon.form.usageFrequency',
        items: this.usageFrequencyTypes,
        rules: 'required',
      },
      {
        name: 'label',
        type: InputType.Select,
        label: 'coupon.form.label',
        items: this.labels,
        default: null,
        itemText: 'name',
        itemValue: '_id',
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'coupon.form.type',
        items: this.types,
        disabled: this.typeDisabledCheck,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'coupon.form.discount',
        default: 0,
        visible: this.discountArticleVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'coupon.form.discount',
        default: 0,
        visible: this.relativeDiscount,
        rules: 'required|max_value:0.99|min_value:0',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'coupon.form.value',
        default: 0,
        visible: this.absoluteValueVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'coupon.form.percent',
        default: 0,
        visible: this.relativeValueVisibleCallbackDefault,
        rules: 'required|max_value:0.99|min_value:0',
      },
      {
        name: 'article',
        type: InputType.Autocomplete,
        label: 'coupon.form.article',
        visible: this.articleVisible,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'coupon.form.bogoArticles',
        visible: this.bogoValueVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'coupon.form.freeArticle',
        visible: this.freeArticleValueVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'uploadedGiftCards',
        type: InputType.File,
        label: 'coupon.form.uploadGiftCards',
        visible: this.uploadGiftCardsVisibleCallbackDefault,
        rules: 'required',
        default: null,
        accept: '.csv',
      },
      { name: 'mov', type: InputType.Text, label: 'coupon.form.mov', default: '0', rules: 'required' },
      { name: 'amount', type: InputType.Text, label: 'coupon.form.amount', default: '0', rules: 'required' },
      {
        name: 'applyOnAnyQuantity',
        type: InputType.Checkbox,
        label: 'coupon.form.applyOnAnyQuantity',
        visible: this.discountArticleVisibleCallbackDefault,
        default: false,
        rules: 'required',
      },
      {
        name: 'isMultipleApplicable',
        type: InputType.Checkbox,
        label: 'coupon.form.isMultipleApplicable',
        default: false,
      },
      { name: 'active', type: InputType.Checkbox, label: 'coupon.form.active', default: true },
      {
        name: 'redeemableAtCheckoutOnly',
        type: InputType.Checkbox,
        default: false,
        label: 'coupon.form.redeemableAtCheckoutOnly',
      },
    ];

    if (this.multiple) {
      return [
        ...items.filter((i: FormItem) => i.name !== 'code'),
        {
          name: 'generateAmount',
          type: InputType.Text,
          default: 10,
          label: 'coupon.form.generateAmount',
          rules: 'required|integer',
        },
      ];
    }

    return items;
  }

  @Emit('venues')
  public onVenueChange(val: string[], form: { countries: string[]; customerGroup: CustomerGroup }) {
    return { venues: val, countries: form.countries, customerGroup: form.customerGroup };
  }

  @Emit('countries')
  public onCountriesChange(val: string[], form: { countries: string[]; customerGroup: CustomerGroup }) {
    return { countries: form.countries, customerGroup: form.customerGroup };
  }

  @Emit('customerGroup')
  public onCustomerGroupChange(val: CustomerGroup, form: { countries: string[]; customerGroup: CustomerGroupEnum }) {
    this.currentCusomerGroup = form.customerGroup;
    return { countries: form.countries, customerGroup: form.customerGroup };
  }

  public getStates(form: { countries: string[] }) {
    if (form && form.countries) {
      const states: string[] = [];
      if (form.countries.indexOf(Country.DE) > -1) {
        states.push(...this.germanStates);
      }
      if (form.countries.indexOf(Country.AU) > -1) {
        states.push(...this.austriaStates);
      }

      return states;
    }

    return [];
  }

  get articleItems() {
    return this.articles.map((a: Article) => {
      return {
        value: a._id,
        text: this.$options.filters!.localized(a.name),
      };
    });
  }

  public absoluteValueVisibleCallbackDefault(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Absolute].indexOf(form.type!) > -1);
  }

  public relativeValueVisibleCallbackDefault(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Relative].indexOf(form.type!) > -1);
  }

  public discountArticleVisibleCallbackDefault(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Absolute_Article].indexOf(form.type!) > -1);
  }

  public relativeDiscount(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Relative_Article].indexOf(form.type!) > -1);
  }

  public articleVisible(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Relative_Article, CouponType.Absolute_Article].indexOf(form.type!) > -1);
  }

  public bogoValueVisibleCallbackDefault(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Bogo].indexOf(form.type!) > -1);
  }

  public uploadGiftCardsVisibleCallbackDefault(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Gift_Card].indexOf(form.type!) > -1);
  }

  public freeArticleValueVisibleCallbackDefault(form: { type?: CouponType }) {
    return !!(form.type && [CouponType.Free_Article, CouponType.Plus_One_Free].indexOf(form.type!) > -1);
  }

  public typeDisabledCheck() {
    return this.editing;
  }

  public getData() {
    const data: Partial<Coupon> = { ...this.$refs.form.form, ...this.$refs.form.form.range };
    if (data.piggyRewardUuid === undefined) {
      data.piggyRewardUuid = '';
    }

    if ([CouponType.Absolute_Article, CouponType.Relative_Article].includes(data.type!)) {
      data.value = {
        discount: data.value,
        articles: data.article,
      };
      delete data.article;
    }

    return data;
  }
}
