export enum CouponType {
  Absolute = 'absolute',
  Relative = 'relative',
  Absolute_Article = 'absolute_article',
  Relative_Article = 'relative_article',
  Gift_Card = 'gift_card',
  Delivery_Fee = 'delivery_fee',
  Bogo = 'bogo',
  Reward = 'reward',
  Free_Article = 'free_article',
  Plus_One_Free = 'plus_one_free',
  Void = 'void',
}
