
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import Notification from '@/mixins/Notification';
import { CupCode } from '@/interfaces/models/CupCode';
import EditCupCodeForm from '@/components/cupCode/EditCupCodeForm.vue';
import StackedForm from '../../mixins/StackedForm';

const venue = namespace('venue');
const cupCode = namespace('cupCode');

@Component({
  components: { VWrapper, EditCupCodeForm },
})
export default class EditCupCode extends mixins(Editable, Notification, StackedForm) {
  @venue.State('active') public venue!: Venue;
  @cupCode.Action('generate') public store!: any;
  @cupCode.Action('update') public update!: any;
  @cupCode.Action('show') public show!: any;
  @cupCode.State('active') public active!: CupCode;

  public $refs!: {
    form: InstanceType<typeof EditCupCodeForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get cupCode() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get title() {
    return this.editing ? 'cupCode.edit' : 'cupCode.create';
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('cupCode');
      await this.show({ id: this.id });
      this.$stopLoading('cupCode');
    }
    if (this.editing && !this.active) {
      this.$router.push({ name: 'cupCode.index' });
    }
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (res) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.id });
        } else {
          try {
            this.$startLoading('storeCupCode');
            await this.store({ ...this.$refs.form.getData(), venue: this.venue?._id });
            this.$router.push({ name: 'cupCode.index' });
          } catch (e) {
            console.log(e);
          } finally {
            this.$stopLoading('storeCupCode');
          }
        }
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
