export enum PromoCodeType {
  Absolute = 'absolute',
  Relative = 'relative',
  Absolute_Article = 'absolute_article',
  Relative_Article = 'relative_article',
  Gift_Card = 'gift_card',
  Delivery_Fee = 'delivery_fee',
  Bogo = 'bogo',
  Reward = 'reward',
  Free_Article = 'free_article',
  Void = 'void',
  Most_Expensive_Article_Discount_Relative = 'most_expensive_article_discount_relative',
  Teedeli_Premium = 'teedeli_premium',
  Article_Discount_Absolute = 'article_discount_absolute',
}
