
import { Component, Emit, Prop } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import { PromoCodeType } from '@/enums/PromoCodeType';
import StackedForm from '@/mixins/StackedForm';
import Countries from '@/mixins/Countries';
import { Article } from '@/interfaces/models/Article';
import { SelectItem } from '@/interfaces/components/SelectItem';
import { FormItem } from '@/interfaces/components/FormItem';
import { PromoCode } from '@/interfaces/models/PromoCode';
import { venueFilter } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class SimpleGeneralForm extends mixins(CustomerGroup, StackedForm, Countries) {
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];
  @Prop({ type: Array, default: () => [] }) public articles!: Article[];
  @Prop({ type: Boolean, required: true }) public editing!: boolean;
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;

  get types() {
    return [
      { text: this.$t('promo.types.absolute'), value: PromoCodeType.Absolute },
      { text: this.$t('promo.types.relative'), value: PromoCodeType.Relative },
      { text: this.$t('promo.types.deliveryFee'), value: PromoCodeType.Delivery_Fee },
      { text: this.$t('promo.types.giftCard'), value: PromoCodeType.Gift_Card },
      { text: this.$t('promo.types.bogo'), value: PromoCodeType.Bogo },
      { text: this.$t('promo.types.reward'), value: PromoCodeType.Reward },
      { text: this.$t('promo.types.freeArticle'), value: PromoCodeType.Free_Article },
      { text: this.$t('promo.types.void'), value: PromoCodeType.Void },
      { text: this.$t('promo.types.absoluteArticle'), value: PromoCodeType.Absolute_Article },
      { text: this.$t('promo.types.relativeArticle'), value: PromoCodeType.Relative_Article },
    ];
  }

  get items() {
    const items: FormItem[] = [
      { name: 'name', type: InputType.Text, label: 'promo.form.name', rules: 'required' },
      { name: 'code', type: InputType.Text, label: 'promo.form.code', rules: 'required' },
      { name: 'range', type: InputType.DateRange, rules: 'required' },
      {
        name: 'countries',
        type: InputType.Select,
        multiple: true,
        label: 'promo.form.countries',
        items: this.selectableCountries,
        change: this.onCountriesChange,
      },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'promo.form.venues',
        multiple: true,
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'promo.form.type',
        items: this.types,
        disabled: this.typeDisabledCheck,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'promo.form.discount',
        default: 0,
        visible: this.absoluteValueVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'promo.form.percent',
        default: 0,
        visible: this.relativeValueVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'article',
        type: InputType.Autocomplete,
        label: 'promo.form.article',
        visible: this.discountArticleVisibleCallbackDefault,
        rules: 'required',
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'promo.form.bogoArticles',
        visible: this.bogoValueVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'promo.form.freeArticle',
        multiple: true,
        visible: this.freeArticleValueVisibleCallbackDefault,
        rules: 'required',
        items: this.articleItems,
      },
      {
        name: 'giftCards',
        type: InputType.File,
        label: 'promo.form.uploadGiftCards',
        visible: this.uploadGiftCardsVisibleCallbackDefault,
        rules: 'required',
        default: null,
        accept: '.csv',
        items: this.articleItems,
      },
      { name: 'mov', type: InputType.Text, label: 'promo.form.mov', default: '0', rules: 'required' },
      { name: 'amount', type: InputType.Text, label: 'promo.form.amount', default: '0', rules: 'required' },
      {
        name: 'isMultipleApplicable',
        type: InputType.Checkbox,
        label: 'promo.form.isMultipleApplicable',
        default: false,
      },
      { name: 'active', type: InputType.Checkbox, label: 'promo.form.active', default: true },
    ];

    if (this.multiple) {
      return [
        ...items.filter((i: FormItem) => i.name !== 'code'),
        {
          name: 'generateAmount',
          type: InputType.Text,
          default: 10,
          label: 'promo.form.generateAmount',
          rules: 'required|integer',
        },
      ];
    }

    return items;
  }

  @Emit('countries')
  public onCountriesChange(val: string[], form: { countries: string[] }) {
    return { countries: form.countries, customerGroup: null };
  }

  get selectableCountries() {
    const countries: string[] = this.venues.map((v: Venue) => (v.country ? v.country.toLowerCase() : v.country));

    return this.countries.filter((i: SelectItem) => countries.includes(i.value as string));
  }

  get articleItems() {
    return this.articles.map((a: Article) => {
      return {
        value: a._id,
        text: this.$options.filters!.localized(a.name),
      };
    });
  }

  public uploadGiftCardsVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Gift_Card].indexOf(form.type!) > -1);
  }

  public absoluteValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Absolute, PromoCodeType.Absolute_Article].indexOf(form.type!) > -1);
  }

  public relativeValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Relative, PromoCodeType.Relative_Article].indexOf(form.type!) > -1);
  }

  public discountArticleVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Relative_Article, PromoCodeType.Absolute_Article].indexOf(form.type!) > -1);
  }

  public bogoValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Bogo].indexOf(form.type!) > -1);
  }

  public freeArticleValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Free_Article].indexOf(form.type!) > -1);
  }

  public typeDisabledCheck() {
    return this.editing;
  }

  public getData() {
    const data: Partial<PromoCode> = { ...this.$refs.form.form, ...this.$refs.form.form.range };
    if ([PromoCodeType.Absolute_Article, PromoCodeType.Relative_Article].includes(data.type!)) {
      data.value = {
        discount: data.value,
        article: data.article,
      };
    }
    return data;
  }
}
