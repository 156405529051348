
import { Component, Emit, Prop } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import { PromoCodeType } from '@/enums/PromoCodeType';
import StackedForm from '@/mixins/StackedForm';
import Countries from '@/mixins/Countries';
import { Article } from '@/interfaces/models/Article';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { FormItem } from '@/interfaces/components/FormItem';
import { PromoCode } from '@/interfaces/models/PromoCode';
import { Country } from '@/enums/Country';
import { venueFilter } from '@/util/helper';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';

@Component({
  components: { VFormBuilder },
})
export default class GeneralForm extends mixins(CustomerGroup, StackedForm, Countries) {
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];
  @Prop({ type: Array, default: () => [] }) public articles!: Article[];
  @Prop({ type: Boolean, required: true }) public editing!: boolean;
  @Prop({ type: String, default: null }) public customerGroup!: CustomerGroup;
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;
  @Prop({ type: Boolean, default: false }) public teedeli!: boolean;

  private origTypeItems: Array<{ text: any; value: string }> = [
    { text: this.$t('promo.types.absolute'), value: PromoCodeType.Absolute },
    { text: this.$t('promo.types.relative'), value: PromoCodeType.Relative },
    { text: this.$t('promo.types.deliveryFee'), value: PromoCodeType.Delivery_Fee },
    { text: this.$t('promo.types.bogo'), value: PromoCodeType.Bogo },
    { text: this.$t('promo.types.giftCard'), value: PromoCodeType.Gift_Card },
    { text: this.$t('promo.types.freeArticle'), value: PromoCodeType.Free_Article },
    { text: this.$t('promo.types.void'), value: PromoCodeType.Void },
    { text: this.$t('promo.types.absoluteArticle'), value: PromoCodeType.Absolute_Article },
    { text: this.$t('promo.types.relativeArticle'), value: PromoCodeType.Relative_Article },
  ];
  // TODO - refactor logic
  private typeItems: Array<{ text: any; value: string }> = this.teedeli
    ? [...this.origTypeItems, ...this.teedeliTypes]
    : this.origTypeItems;

  get teedeliTypes() {
    return [
      {
        text: this.$t('promo.types.mostExpensiveArticleDiscountRelative'),
        value: PromoCodeType.Most_Expensive_Article_Discount_Relative,
      },
      {
        text: this.$t('promo.types.teedeliPremium'),
        value: PromoCodeType.Teedeli_Premium,
      },
    ];
  }

  get types() {
    return this.typeItems;
  }

  get germanStates() {
    return [
      'Berlin',
      'Bayern',
      'Niedersachsen',
      'Baden-Württemberg',
      'Rheinland-Pfalz',
      'Sachsen',
      'Thüringen',
      'Hessen',
      'Nordrhein-Westfalen',
      'Sachsen-Anhalt',
      'Brandenburg',
      'Mecklenburg-Vorpommern',
      'Hamburg',
      'Schleswig-Holstein',
      'Saarland',
      'Bremen',
    ];
  }

  get austriaStates() {
    return [
      'Burgenland',
      'Kärnten',
      'Niederösterreich',
      'Oberösterreich',
      'Salzburg',
      'Steiermark',
      'Tirol',
      'Vorarlberg',
      'Wien',
    ];
  }

  get items() {
    if (this.editing) {
      return this.updateItems;
    }

    return this.teedeli ? this.createTeedeliItems : this.createItems;
  }

  get updateItems() {
    return [
      { name: 'name', type: InputType.Text, label: 'promo.form.name', rules: 'required' },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'promo.form.venues',
        multiple: true,
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'promo.form.type',
        items: this.types,
        visible: false,
        disabled: true,
      },
      {
        name: 'uploadedGiftCards',
        type: InputType.File,
        label: 'promo.form.uploadGiftCards',
        visible: this.uploadGiftCardsVisibleCallbackDefault,
        default: null,
        accept: '.csv',
      },
      { name: 'mov', type: InputType.Text, label: 'promo.form.mov', default: '0', rules: 'required' },
      { name: 'amount', type: InputType.Text, label: 'promo.form.amount', default: '0', rules: 'required' },
      {
        name: 'applyOnAnyQuantity',
        type: InputType.Checkbox,
        label: 'promo.form.applyOnAnyQuantity',
        visible: this.applyOnAnyQuantityDefault,
        default: false,
      },
      {
        name: 'isMultipleApplicable',
        type: InputType.Checkbox,
        label: 'promo.form.isMultipleApplicable',
        default: false,
      },
      { name: 'active', type: InputType.Checkbox, label: 'promo.form.active', default: true },
    ];
  }

  get createItems() {
    const items: FormItem[] = [
      { name: 'name', type: InputType.Text, label: 'promo.form.name', rules: 'required' },
      { name: 'code', type: InputType.Text, label: 'promo.form.code', rules: 'required' },
      { name: 'lightspeedCode', type: InputType.Text, label: 'promo.form.lightspeedCode' },
      { name: 'range', type: InputType.DateRange, rules: 'required' },
      {
        name: 'customerGroup',
        type: InputType.Select,
        default: this.customerGroup ? this.customerGroup : '',
        disabled: !!this.customerGroup,
        label: 'promo.form.customerGroup',
        items: this.customerGroups,
        change: this.onCustomerGroupChange,
      },
      {
        name: 'countries',
        type: InputType.Select,
        multiple: true,
        label: 'promo.form.countries',
        items: this.countries,
        change: this.onCountriesChange,
      },
      { name: 'states', type: InputType.Select, multiple: true, label: 'promo.form.states', items: this.getStates },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'promo.form.venues',
        multiple: true,
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
        change: this.onVenueChange,
      },
      {
        name: 'label',
        type: InputType.Select,
        label: 'promo.form.label',
        items: this.labels,
        default: null,
        itemText: 'name',
        itemValue: '_id',
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'promo.form.type',
        items: this.types,
        disabled: this.typeDisabledCheck,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'promo.form.discount',
        default: 0,
        visible: this.absoluteValueVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'promo.form.percent',
        default: 0,
        visible: this.relativeValueVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'article',
        type: InputType.Autocomplete,
        label: 'promo.form.article',
        visible: this.discountArticleVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'promo.form.bogoArticles',
        visible: this.bogoValueVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'promo.form.freeArticle',
        visible: this.freeArticleValueVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'uploadedGiftCards',
        type: InputType.File,
        label: 'promo.form.uploadGiftCards',
        visible: this.uploadGiftCardsVisibleCallbackDefault,
        rules: 'required',
        default: null,
        accept: '.csv',
      },
      { name: 'mov', type: InputType.Text, label: 'promo.form.mov', default: '0', rules: 'required' },
      { name: 'amount', type: InputType.Text, label: 'promo.form.amount', default: '0', rules: 'required' },
      {
        name: 'applyOnAnyQuantity',
        type: InputType.Checkbox,
        label: 'promo.form.applyOnAnyQuantity',
        visible: this.applyOnAnyQuantityDefault,
        default: false,
      },
      {
        name: 'isMultipleApplicable',
        type: InputType.Checkbox,
        label: 'promo.form.isMultipleApplicable',
        default: false,
      },
      { name: 'active', type: InputType.Checkbox, label: 'promo.form.active', default: true },
    ];

    if (this.multiple) {
      return [
        ...items.filter((i: FormItem) => i.name !== 'code'),
        {
          name: 'generateAmount',
          type: InputType.Text,
          default: 10,
          label: 'promo.form.generateAmount',
          rules: 'required|integer',
        },
      ];
    }

    return items;
  }

  get createTeedeliItems() {
    const items: FormItem[] = [
      { name: 'name', type: InputType.Text, label: 'promo.form.name', rules: 'required' },
      { name: 'range', type: InputType.DateRange, rules: 'required' },
      {
        name: 'customerGroup',
        type: InputType.Select,
        default: CustomerGroupEnum.TEEDELI,
        disabled: true,
        label: 'promo.form.customerGroup',
        items: this.customerGroups,
        change: this.onCustomerGroupChange,
      },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        multiple: true,
        label: 'promo.form.venue',
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
        rules: 'required',
      },
      {
        name: 'type',
        type: InputType.Select,
        label: 'promo.form.type',
        items: this.types,
        disabled: this.typeDisabledCheck,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'promo.form.discount',
        default: 0,
        visible: this.absoluteValueVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'value',
        type: InputType.Text,
        label: 'promo.form.percent',
        default: 0,
        visible: this.relativeValueVisibleCallbackDefault,
        rules: 'required',
      },
      {
        name: 'article',
        type: InputType.Autocomplete,
        label: 'promo.form.article',
        visible: this.discountArticleVisibleCallbackDefault,
        rules: 'required',
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'promo.form.bogoArticles',
        visible: this.bogoValueVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'value',
        type: InputType.Autocomplete,
        label: 'promo.form.freeArticle',
        visible: this.freeArticleValueVisibleCallbackDefault,
        rules: 'required',
        multiple: true,
        items: this.articleItems,
      },
      {
        name: 'uploadedGiftCards',
        type: InputType.File,
        label: 'promo.form.uploadGiftCards',
        visible: this.uploadGiftCardsVisibleCallbackDefault,
        rules: 'required',
        default: null,
        accept: '.csv',
      },
      { name: 'mov', type: InputType.Text, label: 'promo.form.mov', default: '0', rules: 'required' },
      {
        name: 'amountPromo',
        type: InputType.Text,
        label: 'promo.form.amountTeedeli',
        default: '0',
        rules: 'required',
      },
      {
        name: 'amount',
        type: InputType.Text,
        label: 'promo.form.amountUsesTeedeli',
        default: '0',
        rules: 'required',
      },
      {
        name: 'applyOnAnyQuantity',
        type: InputType.Checkbox,
        label: 'promo.form.applyOnAnyQuantity',
        visible: this.applyOnAnyQuantityDefault,
        default: false,
      },
      {
        name: 'isMultipleApplicable',
        type: InputType.Checkbox,
        label: 'promo.form.isMultipleApplicable',
        default: false,
      },
      { name: 'active', type: InputType.Checkbox, label: 'promo.form.active', default: true },
    ];

    return items;
  }

  @Emit('venues')
  public onVenueChange(val: string[], form: { countries: string[]; customerGroup: CustomerGroup }) {
    return { venues: val, countries: form.countries, customerGroup: form.customerGroup };
  }

  @Emit('countries')
  public onCountriesChange(val: string[], form: { countries: string[]; customerGroup: CustomerGroup }) {
    return { countries: form.countries, customerGroup: form.customerGroup };
  }

  @Emit('customerGroup')
  public onCustomerGroupChange(val: CustomerGroup[], form: { countries: string[]; customerGroup: CustomerGroup }) {
    if (val.toString() === CustomerGroupEnum.TEEDELI) {
      this.typeItems = [...this.origTypeItems, ...this.teedeliTypes];
    } else {
      this.typeItems = [...this.origTypeItems];
    }
    return { countries: form.countries, customerGroup: form.customerGroup };
  }

  public getStates(form: { countries: string[] }) {
    if (form && form.countries) {
      const states: string[] = [];
      if (form.countries.indexOf(Country.DE) > -1) {
        states.push(...this.germanStates);
      }
      if (form.countries.indexOf(Country.AU) > -1) {
        states.push(...this.austriaStates);
      }

      return states;
    }

    return [];
  }

  get articleItems() {
    return this.articles.map((a: Article) => {
      return {
        value: a._id,
        text: this.$options.filters!.localized(a.name),
      };
    });
  }

  public absoluteValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Absolute, PromoCodeType.Absolute_Article].indexOf(form.type!) > -1);
  }

  public relativeValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(
      form.type &&
      [
        PromoCodeType.Relative,
        PromoCodeType.Relative_Article,
        PromoCodeType.Teedeli_Premium,
        PromoCodeType.Most_Expensive_Article_Discount_Relative,
      ].indexOf(form.type!) > -1
    );
  }

  public discountArticleVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Relative_Article, PromoCodeType.Absolute_Article].indexOf(form.type!) > -1);
  }

  public applyOnAnyQuantityDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Relative_Article, PromoCodeType.Absolute_Article].indexOf(form.type!) > -1);
  }

  public bogoValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Bogo].indexOf(form.type!) > -1);
  }

  public uploadGiftCardsVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Gift_Card].indexOf(form.type!) > -1);
  }

  public freeArticleValueVisibleCallbackDefault(form: { type?: PromoCodeType }) {
    return !!(form.type && [PromoCodeType.Free_Article].indexOf(form.type!) > -1);
  }

  public typeDisabledCheck() {
    return this.editing;
  }

  public getData() {
    const data: Partial<PromoCode> = { ...this.$refs.form.form, ...this.$refs.form.form.range };
    if ([PromoCodeType.Absolute_Article, PromoCodeType.Relative_Article].includes(data.type!)) {
      data.value = {
        discount: data.value,
        articles: data.article,
      };
    }
    return data;
  }
}
