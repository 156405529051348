
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { CupCode as CupCodeModel } from '@/interfaces/models/CupCode';
import CustomerGroup from '@/mixins/CustomerGroup';

@Component({
  components: { VFormBuilder },
})
export default class EditCupCodeForm extends mixins(StackedForm, CustomerGroup) {
  public $refs!: {
    cupCode: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get initValues() {
    const cupCode: any = this.initialValues as CupCodeModel;

    return cupCode;
  }

  get items() {
    return [
      { name: 'quantity', type: InputType.Text, label: 'cupCode.form.quantity', rules: 'required|quantity_number' },
      { name: 'startingFrom', type: InputType.Text, label: 'cupCode.form.startingFrom', rules: 'required' },
      {
        name: 'customerGroups',
        type: InputType.Select,
        label: 'cupCode.form.customerGroup',
        multiple: true,
        items: this.customerGroups,
        rules: 'required',
      },
      { name: 'active', type: InputType.Checkbox, label: 'cupCode.form.active', default: true },
    ];
  }

  public validate() {
    return this.$refs.cupCode.validate();
  }

  public getData() {
    return {
      ...this.$refs.cupCode.form,
    };
  }
}
